import React, { useContext, useCallback } from "react";
import styles from "./rooms.module.css";
import { BookingDataContext } from "containers/data_context";
import RoomGrid from './room_grid';
import Placeholder from "components/placeholder";
import { FrownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import DummyImage from "assets/images/dummy-image-square.jpg";


export default function Rooms({ property = {} }) {
    const { t } = useTranslation();
    const { roomsInfo } = useContext(BookingDataContext);
    const { data: roomsData, isLoading } = roomsInfo;
    if (!Array.isArray(roomsData)) {
        return null;
    }

    return (
        <>

            <div class="modal fade" id="roomsModal" tabindex="-1" aria-labelledby="roomsModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <h5 className={styles.RoomsTitle}>Rooms</h5>
                            <div className="row g-3">
                                {
                                    roomsData.length ? (roomsData.map((room, rowIndex) => (
                                        <RoomGrid room={room}></RoomGrid>
                                    ))) : (
                                        <Placeholder
                                            icon={<FrownOutlined />}
                                            text={t("rates_table:no_available_rates_placeholder")}
                                        />
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
