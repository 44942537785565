import React, { useState, useCallback } from "react";
import { Button } from "react-bootstrap";

import styles from "./room_grid.module.css";
import DummyImage from "assets/images/dummy-image-square.jpg";
import RoomDetailsModal from "../../../rates_table/room_type/room_info/room_title/room_details_modal";

export default function RoomGrid({ room = {} }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalToggle = useCallback(() => {
        setIsModalOpen(!isModalOpen);
    }, [isModalOpen, setIsModalOpen]);

    return (
        <div className="col-md-6 mb-3">
            <div className="mb-2">
                <div className={styles.imgContainer}>
                    <a href="#"><img className="w-100 img-fluid" src={(room.photos.length) ? (room.photos[0].url + '-/resize/575x/575.jpg') : (DummyImage)} alt="" /></a>
                </div>
            </div>
            <Button variant="link" className={styles.RoomsSubTtile} onClick={handleModalToggle}>
                <strong>{room.title}</strong>
            </Button>
            <RoomDetailsModal room={room} show={isModalOpen} onHide={handleModalToggle} />


            {/* </div> */}
            {/* <div>
                <i class="fa-solid fa-person me-1"></i>
                <i class="fa-solid fa-person me-1"></i>
                <i class="fa-solid fa-person me-1"></i>
                <div>3 Guests</div>
            </div> */}
        </div>
    );
}
