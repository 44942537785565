import React from "react";
import About from "../about";
import Rooms from "../rooms";
import styles from "./header_menu.module.css";

export default function HeaderMenu({ property = {} }) {
  return (
    <>
      <ul className={styles.menuList}>
        <li><a href="#hotel_rates_section" data-toggle="modal" data-target="#roomsModal" type="button">Rooms</a></li>
        <li><a href="#about_section" data-toggle="modal" data-target="#exampleModal" type="button">About</a></li>
        {/* <li><a href="#">Map</a></li> */}
      </ul>
      <About property={property}></About>
      <Rooms property={property}></Rooms>
    </>
  );
}
