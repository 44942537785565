import React from "react";

import Cell from "components/layout/cell";

import BedOptions from "./bed_options";
import Description from "./description";
import RoomAvailability from "./room_availability";
import RoomFacilities from "./room_facilities";
import RoomTitle from "./room_title";
import DummyImage from "assets/images/dummy-image-square.jpg";

import styles from "./room_info.module.css";

export default function RoomInfo({ roomType }) {
  const { availability, bedOptions, facilities, description } = roomType;

  return (
    <Cell className={styles.roomContainer}>
      <div className="row">
        <div className="col-md-6">
          <img className="mb-2" src={(roomType.photos.length)?(roomType.photos[0].url+'-/resize/575x/575.jpg'):(DummyImage)} alt="" />
        </div>
        <div className="col-md-6">
          <div>
            <RoomTitle room={roomType} />
            <Description description={description} />
            <RoomAvailability availability={availability} />
            <BedOptions bedOptions={bedOptions} />
            <RoomFacilities facilities={facilities} />
          </div>
        </div>
      </div>
    </Cell >
  );
}
