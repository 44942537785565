import React from "react";
import styles from "./about.module.css";
import PhotosGallery from "../../photo_gallery";
import Facilities from "../../hotel_facilities_section";
import HotelInfo from "../../hotel_additional_info_section";
import Footer from "../../footer";
// import PhotosGallery from "../../room_details_modal";

export default function About({ property = {} }) {
  const isPhotosPresent = Boolean(property.photos.length);

  return (
    <>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {isPhotosPresent && <PhotosGallery photos={property.photos} />}
              <Facilities property={property}></Facilities>
              <HotelInfo property={property}></HotelInfo>
              <Footer property={property} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
