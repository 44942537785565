import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import SectionWrapper from "components/layout/section_wrapper";
import RatesTable from "components/rates_table";
import SectionTitle from "components/section_title";

export default function HotelRatesSection() {
  const { t } = useTranslation();

  return (
    <div className="mb-5">
      <SectionWrapper id="hotel_rates_section" theme="dark">
        <Col>
          <SectionTitle>{t("rates_table:title")}</SectionTitle>
          <RatesTable />
        </Col>
      </SectionWrapper>
    </div>
  );
}
